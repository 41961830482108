module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.yourlocalsecurity.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-NTZF9J","brandTokens":["ADT"],"mapiBrandToken":"ADT","siteName":"yourlocalsecurity","alternateName":"yourlocalsecurity.com","siteURL":"https://www.yourlocalsecurity.com","defaultTitleTemplate":"","defaultPhone":"8662389934","phoneSymbol":"-","defaultPromoCode":"13688","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046066","vwoProjectID":"894940","datasetManagerAPIKey":"57b1b10643414acf82e7b71e32ee1343"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"yourlocalsecurity","short_name":"yourlocalsecurity","start_url":"/","background_color":"#0066DD","theme_color":"#0066DD","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ebcbf76e0a14497baec091e76662c463"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React from 'react'
import { ThemeProvider } from '@leshen/ui'
import GlobalStyles from './CustomStyles/GlobalStyles'
import CityLinkStyles from './CustomStyles/CityLinkStyles'
import siteTheme from 'gatsby-theme-adt/theme'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>
    <GlobalStyles />
    <CityLinkStyles />
    {children}
  </ThemeProvider>
)

export default Wrapper

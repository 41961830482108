import { css, Global, withTheme } from '@emotion/react'
import React from 'react'

const GlobalStyles = withTheme(({theme}) => (
  <Global
    styles={css`
      .subnav {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      .subnav a:last-child {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      .leshen-header-desktop .primary-link:hover {
        > .leshen-link {
          color: ${theme.colors.white.base.hex} !important;
        }
      }
    `}
  />
))

export default GlobalStyles
